var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "homeContainer" },
    [
      _c("ThePlayer"),
      _c("EmbedBeatTable", {
        attrs: {
          beatPlaylist: _vm.beats,
          loadMoreEnabled: _vm.loadMoreEnabled,
        },
        on: {
          fetchMoreData: function ($event) {
            return _vm.updateBeatsTable($event, _vm.beats.length)
          },
          onSearchKeywordChange: _vm.onSearchKeywordChange,
        },
      }),
      _c(
        "a",
        { staticClass: "creditsTag", attrs: { href: "#" } },
        [
          _c("p", [_vm._v("Powered By")]),
          _c("icon", {
            staticClass: "creditsTag__logo",
            attrs: { name: "logo" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }