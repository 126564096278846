var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "homeContainer__playerContainer" }, [
    _c("img", {
      staticClass: "player__backgroundImage",
      attrs: { src: _vm.beatImageOrProducerLogo(_vm.beat) },
    }),
    _c("div", { staticClass: "player" }, [
      _c("div", { staticClass: "player__trackWrapper" }, [
        _c(
          "div",
          {
            staticClass: "player__trackImage",
            style: {
              backgroundImage:
                "url(" + _vm.beatImageOrProducerLogo(_vm.beat) + ")",
            },
          },
          [
            _c("div", { staticClass: "player__controls" }, [
              _c("div", { staticClass: "player__controlsBg" }),
              _c(
                "div",
                { staticClass: "player__controlsIconWrapper" },
                [
                  _c("icon", {
                    staticClass: "player__iconPlayPause",
                    attrs: { name: _vm.isAudioPaused ? "play-o" : "pause-o" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.TOGGLE_PLAY_STATUS.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c("div", { staticClass: "player__controllers" }, [
          _c(
            "div",
            {
              staticClass: "player__timeBar",
              on: {
                mousedown: _vm.startDrag,
                mouseup: function ($event) {
                  return _vm.stopDrag($event)
                },
                mousemove: function ($event) {
                  return _vm.doDrag($event)
                },
                touchmove: function ($event) {
                  return _vm.setNewCurrentPosition($event, true)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "player__slider",
                  attrs: {
                    role: "slider",
                    "aria-valuemin": "0",
                    "aria-valuenow": _vm.currentPosition,
                    "aria-valuemax": _vm.trackTotalDuration,
                    "aria-orientation": "horizontal",
                  },
                },
                [
                  _c("div", {
                    staticClass: "player__currentPosition",
                    style: _vm.playerBarStyling,
                  }),
                ]
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "player__buttons" },
            [
              _c(
                "router-link",
                {
                  staticClass: "player__button",
                  attrs: { to: { name: "Cart" } },
                },
                [
                  _vm._v("View Cart"),
                  _c(
                    "p",
                    {
                      staticClass: "player__buttonItem player__buttonItem--bg",
                    },
                    [_vm._v(_vm._s(!_vm.cart ? 0 : _vm.cart.length))]
                  ),
                ]
              ),
              _c(
                "router-link",
                {
                  staticClass: "player__button",
                  attrs: { to: { name: "Cart" } },
                },
                [
                  _vm._v("Checkout"),
                  _c("icon", {
                    staticClass: "player__buttonItem",
                    attrs: { name: "credit-card-o" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }