var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "homeContainer__beatsContainer" }, [
    _c("div", { staticClass: "mainTitle" }, [
      _c("div", [
        _c("h3", { staticClass: "h3" }, [_vm._v("Beat Store")]),
        _c(
          "p",
          [
            _c("icon", {
              staticClass: "mainTitle__icon",
              attrs: { name: "paypal" },
            }),
            _vm._v("Secure Checkout via PayPal"),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "beatTable" },
      [
        _c(
          "div",
          { staticClass: "searchBar" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.searchKeyword,
                  expression: "searchKeyword",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "formInput formInput--fullWidth",
              attrs: { type: "text", placeholder: "Search..." },
              domProps: { value: _vm.searchKeyword },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchKeyword = $event.target.value.trim()
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _c("icon", {
              staticClass: "formInput__icon",
              attrs: { name: "search" },
            }),
          ],
          1
        ),
        _vm._l(_vm.beatPlaylist, function (beat, index) {
          return _c("EmbedBeatTableItem", {
            key: beat.id,
            attrs: { index: index, beatPlaylist: _vm.beatPlaylist },
          })
        }),
        _vm.loadMoreEnabled
          ? _c(
              "infinite-loading",
              {
                attrs: { spinner: "spiral" },
                on: { infinite: _vm.infiniteHandler },
              },
              [
                _c("span", { attrs: { slot: "no-more" }, slot: "no-more" }),
                _c("span", {
                  attrs: { slot: "no-results" },
                  slot: "no-results",
                }),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }