var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tableRow",
      class: _vm.audioClasses,
      on: { click: _vm.togglePlayStatus },
    },
    [
      _c("div", { staticClass: "tableCell" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value: _vm.beatImageOrProducerLogo(_vm.beat),
              expression: "beatImageOrProducerLogo(beat)",
            },
          ],
          staticClass: "tableCell__image",
        }),
      ]),
      _c("div", { staticClass: "tableCell tableCell--sm" }, [
        _c("p", { staticClass: "tableCell__name" }, [
          _vm._v(_vm._s(_vm.beat.name)),
        ]),
      ]),
      _c("div", { staticClass: "tableCell" }, [
        _vm._v(_vm._s(_vm._f("time")(_vm.beat.length))),
      ]),
      _c("div", { staticClass: "tableCell" }, [_vm._v(_vm._s(_vm.beat.bpm))]),
      _c("div", { staticClass: "tableCell tableCell--icons" }, [
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.beat.demo_download_allowed,
                expression: "beat.demo_download_allowed",
              },
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "Demo Download",
                expression: "'Demo Download'",
              },
            ],
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.demoDownloadBeat(_vm.beat)
              },
            },
          },
          [_c("icon", { attrs: { name: "download-o" } })],
          1
        ),
        _c(
          "a",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "Add To Cart",
                expression: "'Add To Cart'",
              },
            ],
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.SET_ACTIVE_MODAL({
                  modal: _vm.ModalType.LicenseOptionSelection,
                  payload: _vm.beat,
                })
              },
            },
          },
          [_c("icon", { attrs: { name: "cart-o" } })],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }